import React from "react"
import { FieldText } from "../fields"
import { FormInputTextData } from "../../../config/config-data";

const Step3 = () => {
  return (
    <div id="leadform_1662793822167">
      {FormInputTextData.map(({ form_field_name, form_input_price, form_input_hide_price, form_input_help_image, form_input_help_text, form_input_required }) => (
      <FieldText
        key={form_field_name}
        name={form_field_name}
        label={form_field_name}
        price={form_input_price}
        hidePrice={form_input_hide_price}
        placeholder={`Your ${form_field_name}`}
        required={form_input_required ? `Warning: ${form_field_name} Is Required` : false}
        helptext={form_input_help_text}
        helpimage={form_input_help_image}
      />
      ))}
    </div>
  );
};

export default Step3;
